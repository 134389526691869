@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,500;1,600;1,700&family=Open+Sans:wght@300;400;600;700&display=swap);
.icon-div{
    list-style-type: none;
    display: flex;
    padding: 0;
    font-size: 20px;
}

.icon-div li{
    margin-right: 20px;
}
.icon-div li a{
    color: #ffffff;
    font-size: 2rem;
}

.icon-div li:first-child{
    margin-left: 0;
}

.icon-div li:last-child{
    margin-right: 0;
}

.home-container {
    background-image: url(/static/media/ollie.e49b94b4.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 22% 0;
    height: 100vh;
    padding: 0;
}

.home-container::before {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHUlEQVQIW2NgY2OzYUACYL6+vn4UsgAynwwBEB8ARuIGpsZxGOoAAAAASUVORK5CYII=);
    opacity: .6;
    position: fixed;
}

.intro-name-div {
    text-align: center;
    position: relative;
    top: 25%;
}

.intro-name-div h2 {
    font-size: 3rem;
    line-height: 0%;
    margin-bottom: 28px;
    color: #ffffff;
    text-shadow: 2px 5px 11px #000000;
}

.intro-name-div h1 {
    font-size: 7rem;
    color: #55da65;
    text-shadow: 2px 4px 13px #000000;
}

.intro-name-div h1:hover,
.intro-name-div h2:hover{
    cursor: default;
}

.icon-div {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 35px;
    margin-bottom: 27px;
}

.icon-div li {
    margin: 0 15px;
}

.arrow-next {
    text-align: center;
    font-size: 2rem;
    color: #ffffff;
}

.arrow-next:hover {
    color: #ffffff;
}


.project-details-container {
    margin-top: 40px;
    padding-top: 10px;
}

.project-details-container h4{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #636363;
    padding-right: 10px;
    padding-bottom: 4px;
    border-radius: 4px;
    font-size: 1.65vw;
}

.details-h4-mobile{
    font-size: 22px!important;
}

.project-details-container p{
    margin-bottom: 0px;
    font-weight: 500;
    font-size: .9vw;
    letter-spacing: 1px;
}

.desktop-portfolio-small p{
    font-size: 15px;
    margin-bottom: 15px;
}

.tech-list-ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap
}


.tech-list-ul li {
    margin-right: 20px;
    font-weight: 500;
    font-size: .9vw;
    letter-spacing: 1px;
}

.desktop-portfolio-small .tech-list-ul li {
    font-size: 15px;
}

.tech-list-ul li:last-of-type{
    margin-right: 0;
}
.active-icon-switch{
    font-size: 30px;
}

.yappe-dot:hover{
    transform: scale(1.35);
    cursor: pointer;
    transition: .25s;
    color: #1ee09d;
}

.trivia-dot:hover{
    transform: scale(1.35);
    cursor: pointer;
    transition: .25s;
    color: #c1e200;
}

.google-dot:hover{
    transform: scale(1.35);
    cursor: pointer;
    transition: .25s;
    color: #5aaaff;
}

.yappe-active{
    color: #1ee09d;
    -webkit-filter: drop-shadow( 0 1px 2px #212529);
            filter: drop-shadow( 0 1px 2px #212529);
}

svg.svg-inline--fa.fa-gamepad.fa-w-20.active-icon-switch.trivia-active{
    color: #c1e200;
    -webkit-filter: drop-shadow( 0 1px 2px #212529);
            filter: drop-shadow( 0 1px 2px #212529);
};
.hide{
  display: none;
}

.desktop-portfolio{
  color:black
}

.desktop-portfolio.card {
  width: 80%;
  margin: auto;
  border-radius: 25px;
  box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.4);
  -webkit-box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.4);
  background: #fffffff7;
}


.desktop-portfolio-small .desktop-portfolio.card {
  width: 100%;
  max-width: 600px;
}


.desktop-project-title {
  font-weight: 600;
  font-size: 1.75rem;
  font-family: 'Montserrat', sans-serif;
}

.desktop-img{
  width: 100%;
  border-radius: 10px;
  box-shadow: 3px 3px 14px 0px rgba(0,0,0,0.27);
};


/* .desktop-portfolio-image-div {
  margin-bottom: 40px;
} */

.desktop-portfolio .desktop-img-div {
  width: 75%;
  margin: auto;
}

.mobile-img-div {
  position: absolute;
  top: 80px;
  right: 17%;
  width: 25%;
}

.desktop-img-div {
  width: 90%;
  display: block;
  margin: auto;
  border-radius: 10px;
  box-shadow: 3px 3px 14px 0px rgba(0,0,0,0.27);
}

/* .desktop-portfolio-image-div img .mobile-img{
  width: 100%
}; */

.desktop-portfolio-btns{
  margin: 10px 0;
  display: flex;

  margin: -3px 0 25px;
  display: flex;
}

.desktop-portfolio-btns .neu-button-white{
  z-index: 1;
}

.desktop-portfolio-btns .neu-button-white span {
  color: #9c9c9c;
}

.desktop-portfolio-btns .neu-button-white:first-of-type{
  margin-right: 20px;
}

.project-select-ul{
  position: fixed;
  margin-bottom: 0;
  margin-top: 7vh;
  right: 1%;
  top: 35%;
  list-style: none;
  padding: 0;
  text-align: center;
}

.neu-button-white{
  padding: 0px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: .75px;
  color: #9c9c9c;
  border-bottom: solid #ffffff00 2px;
  transition: 0.35s;
}

.neu-button-white:hover {
  padding-right: 45px;
  border-radius: 4px;
  text-decoration: none;
  border-bottom: solid #636363 2px;
}

.neu-button-white:hover span{
  color: #636363;
}

.project-select-ul li {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #ededed;
  width: 45px;
  height: 45px;
  text-shadow: 2px 2px 4px #000000;
}

.project-select-ul li .google-active{
  color: #5aaaff;
  -webkit-filter: drop-shadow( 0 1px 2px #212529);
          filter: drop-shadow( 0 1px 2px #212529);
}

.project-select-ul li:last-of-type {
  margin-bottom: 0;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    text-align:center;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  
  }
  
  .sidenav a:hover{
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    /* color: #55da65; */
    top: 5px;
    right: 19px;
    font-size: 2rem;
    margin-left: 50px;
  }

  .nav-icon-span{
    position: absolute;
    right: 0px;
    top: 5px;
    font-size: 2rem;
    /* color: #55da65; */
    z-index: 50;
}
body{
    background-color: #262626;
    overflow-x: hidden;
}

.background-title {
    position: fixed;
    font-size: 9vw;
    font-weight: bolder;
    color: rgba(30, 224, 157, 0.15);
    bottom: 3%;
    left: -1%;
    z-index: -1;
    cursor: default;
}

.about-container{
    padding: 0 1.75rem;
    height: 100vh;
    position: fixed;
    width: 30vw;
    overflow: hidden;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 4px 0px 24px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 4px 0px 24px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 0px 24px -6px rgba(0,0,0,0.75);
}

.about-container-mobile{
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    background-color: transparent;
    box-shadow: none;
}

.about-container .row{
    height: inherit;
}

.about-container h3{
    margin-top: 3vh;
    margin-bottom: 2px;
    font-size: 1.55vw;
    color: #9c9c9c;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    letter-spacing: .75px;
}

.about-container-mobile h3{
    margin-top: 2vh;
    color: #ffffff;
    font-size: 23px;
}


.about-page-container h3{
    margin-top: 45px;
}


.about h1{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-bottom: 13px;
    color: rgb(30, 224, 157);
    font-size: 1vw;
    text-align: left;
}

.about h1{
    font-size: 15px;
}

.skills-div h4{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 1.45vw;
    color: #1ee09d;
    text-align: center;
}

.skills-div-mobile h4{
    font-size: 23px;
}

.about p{
    margin-bottom: 11px;
    font-weight: 500;
    font-size: .85vw;
    color: #000000;
    letter-spacing: 1px;
}

.about-container-mobile .about p{
    font-size: 12px;
    color: #ffffff;
}

.skills-div {
    margin-top: 5vh;
    margin-bottom: 35px;
}

.skills-div h4 {
    margin-bottom: 10px;
}

.skills-div ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
}

.skills-div li {
    padding-right: 10px;
    margin-right: 5px;
    font-weight: 500;
    font-size: .9vw;
    color: #000000;
    letter-spacing: 1px;
}

.about-container-mobile .skills-div li {
    font-size: 12px;
    color: #ffffff;
}

.about-container .icon-div{
    margin: 10px auto -15px;
}

.about-container .icon-div li{
    margin: 0 15px;
    z-index: 10;

}

.about-container .icon-div li:first-of-type{
    margin-left: 0;
}

.about-container .icon-div li:last-of-type{
    margin-right: 0;
}

.about-container .icon-div li a{
    color: #636363;
    font-size: 1.5rem;
}

.about-container-mobile .icon-div li a{
    color: #1f1f1fe3;
}

.about-container .linkedIn-icon:hover{
    color: #0073b1;
}

.about-container .resume-icon:hover{
    color: #B50000;
}

.about-container .github-icon:hover{
    color: #000000;
}


.about-email-link{
    color: #636363;
    display: block;
    text-align: center;
    letter-spacing: 1px;
    font-size: 1vw;
    font-weight: 600;
}

.about-container-mobile .about-email-link{
    color: #1f1f1fe3;
    font-size: 1.11rem;
}

.about-email-link span{
    color: #1ee09d;
    letter-spacing: 1px;
    font-size: 1.75rem;
    position: relative;
    font-weight: 600;
    bottom: -5px;
}

.about-email-link:hover{
    /* color: #000; */
    text-decoration: none;
    color: #464646;
}
body {
    font-family: 'Open Sans', sans-serif;
    color: #000;
    background-color: #262626;
}

.profile-container .profile-row{
    background: rgb(35,173,105);
    background: linear-gradient(15deg, rgba(35,173,105,1) 0%, rgba(52,143,144,1) 57%, rgba(33,92,139,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#23ad69",endColorstr="#215c8b",GradientType=1);
}

.svg-div svg {
    margin: 30px 73px 50px;
}

.app {
    position: relative;
  }
  
  .page {
    position: absolute;
    width: 100%;
    /* left: 15px;
    right: 15px; */
  }
  
  .page-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  
  .page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .page-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  
